import React, { useEffect } from "react"
import { graphql } from "gatsby"
import ClassroomLayout from "../../layout/classroom-layout"
import { RichTextRenderer } from "@webiny/react-rich-text-renderer"
import TopicHeader from "../topic-header"
import { Card, Container, Row, Col } from "react-bootstrap"
import { getTopicData, loadAssets } from "../topic-helper"
import Asset from "../../asset/asset"
import { useDispatch } from "react-redux"
import { setContextSidebar } from "../../../../redux/app-actions"

export default function Content4x8(props) {
  const dispatch = useDispatch()

  const { pageContext } = props
  const { breadcrumb, next, previous } = pageContext

  const { Course, Module, ParentTopic, Topic } = getTopicData(props)

  const assets = loadAssets(Topic)

  useEffect(() => {
    dispatch(
      setContextSidebar({
        course: Course,
        module: Module,
        parentTopic: ParentTopic,
        topic: Topic,
      })
    )
  }, [dispatch])

  // get column class based on content being null
  const getColClass = (a) => {
    return a?.content?.length > 0  ? "4" : "12"
  }

  return (
    <ClassroomLayout>
      <TopicHeader
        next={next}
        previous={previous}
        breadcrumb={breadcrumb}
        topic={Topic}
      ></TopicHeader>
      <Container>
        <Row>
          <Col>
            {Topic.content ? <RichTextRenderer data={Topic.content} /> : ""}
          </Col>
        </Row>
        {assets?.map(a => (
          <Card className="mb-4" key={a.key}>
            <Card.Body>
              <Card.Title>{a.title}</Card.Title>

              <Row>
                <Col xs="12" md={getColClass(a)}>
                  <Asset a={a} />
                </Col>
                {a?.content?.length > 0 ? (
                  <Col xs="12" md="8">
                    <RichTextRenderer data={a.content} />
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </Card.Body>
          </Card>
        ))}
      </Container>
    </ClassroomLayout>
  )
}

export const query = graphql`
  query ($id: ID, $cid: ID, $mid: ID, $ptid: ID = "") {
    scocms {
      getTopic(where: { id: $id }) {
        ...topicFragment
      }

      getParentTopic: getTopic(where: { id: $ptid }) {
        ...parentTopicFragment
      }

      getCourse(where: { id: $cid }) {
        ...courseFragment
      }

      getModule(where: { id: $mid }) {
        ...moduleFragment
      }
    }
  }
`
